import React, { FunctionComponent } from 'react'
import CartAdditionalInformation from '../../entities/CartAdditionalInformation'
import useTranslation from '../../hooks/useTranslation'
import camelCase from 'lodash.camelcase'
import { Markup } from 'interweave'
import style from './cartboxAdditionalInformation.css'

export type Props = {
    additionalInformation: CartAdditionalInformation
}

const CartboxAdditionalInformation: FunctionComponent<Props> = (props) => {
    const {additionalInformation} = props
    const {t} = useTranslation()

    if (!additionalInformation) {
        return null
    }

    const rowElements = additionalInformation?.map((additionalInfo) => {
        return additionalInfo?.entries?.map((entry) => {
            const {  id, value } = entry
            const title = t(`additionalInformation.${camelCase(id)}`)

            return (
                <div key={id} className={style.row}>
                <span className={style.title}>
                    {title}
                </span>
                <span className={style.value}>
                    <Markup content={value}/>
                </span>
                </div>
            )
        })
    })

    return (
        <div>
            {rowElements}
        </div>
    )
}

export default CartboxAdditionalInformation
