import {
    createAction,
    createAsyncAction,
} from 'typesafe-actions'
import VehicleCode from '../../../entities/VehicleCode'
import VehicleCodeStatus from '../../../entities/VehicleCodeStatus'
import MetaAsyncHandler from '../../../entities/MetaAsyncHandler'
import { VehicleCodeStatusState } from '../../reducers/vehicleCodeStatusReducer'

export const createVehicleCodeAsync = createAsyncAction(
    'CMD / VehicleCode / create request',
    'EVT / VehicleCode / create success',
    'EVT / VehicleCode / create failure',
)<[undefined, MetaAsyncHandler], {vehicleCode: VehicleCode}, Error>()

export const setVehicleCodeState = createAction('DOC / VehicleCode / set vehicle code')<VehicleCode>()
export const setVehicleCodeModalShouldBeShownState = createAction('DOC / boolean / set display state')<boolean>()
export const loadConfigurationByVehicleCodeAsync = createAsyncAction(
    'CMD / VehicleCode / get configuration request',
    'EVT / VehicleCode / get configuration success',
    'EVT / VehicleCode / get configuration failure',
)<VehicleCode, VehicleCodeStatus, Error>()

export const vehicleCodeChanged = createAction('EVT / VehicleCode / vehicle code changed')()

export const setVehicleCodeStatus = createAction('DOC / VehicleCodeRescue / set state')<VehicleCodeStatusState>()
export const resetVehicleCodeStatus = createAction('DOC / VehicleCodeRescue / reset state')()
