import React, { FunctionComponent } from 'react'
import { OrderKey } from '../../entities/ConfigurationResponse'
import useTranslation from '../../hooks/useTranslation'
import { Table, Td, Tr } from '../table'
import style from './orderKeyView.css'
import FoldableSection from '../../base-components/foldable-section/FoldableSection'

export type Props = {
    orderKey: OrderKey
}

const OrderKeyView: FunctionComponent<Props> = (props) => {
    const { orderKey } = props
    const { t } = useTranslation()

    if (orderKey === null) {
        return null
    }

    const tableRowElements = Object.entries(orderKey)
        .filter(([id]) => id !== 'combined')
        .map(([id, value]) => (
            <Tr key={id}>
                <Td className={style.td}>{t(`overview.orderKey.${id}`)}</Td>
                <Td className={`${style.td} ${style.orderKey}`}>{value}</Td>
            </Tr>
        ))

    const content = (
        <>
            <p className={style.combinedKey}>
                <b data-cy="orderKey">
                    {orderKey.combined}
                </b>
            </p>
            <Table>
                <tbody>
                    {tableRowElements}
                </tbody>
            </Table>
        </>
    )

    return (
        <FoldableSection
            title={t('overview.orderKey.title')}
            content={content}
            toggleTitle="Toggle Order Key View"
        />
    )
}

export default OrderKeyView
