import React, { FunctionComponent } from 'react'
import AudiLogoIcon from '../../assets/media/audi-logo.svg'
import VwLogoIcon from '../../assets/media/vw-logo.svg'
import VwnLogoIncon from '../../assets/media/vwn-logo-de.svg'
import style from './logo.css'

export type Props = {
    isHidden: boolean
    brand: string
    resetConfiguration: Function
}

const Logo: FunctionComponent<Props> = (props) => {
    const { isHidden, brand, resetConfiguration } = props
    const logoMap = {
        audi: AudiLogoIcon,
        vw: VwLogoIcon,
        vwn: VwnLogoIncon,
    }

    const clickHandler = (): void => {
        resetConfiguration()
    }

    const LogoIcon = logoMap[brand]

    const wrapperClassName = [
        style.wrapper,
        style[`wrapper-${brand}`],
        isHidden ? style.hidden : '',
    ].join(' ')

    const iconClassName = [
        style.icon,
        style[`icon-${brand}`],
    ].join(' ')

    return (
        <div className={wrapperClassName}>
            <button className={style.iconButtonWrapper} onClick={clickHandler}>
                <LogoIcon className={iconClassName}/>
            </button>
        </div>
    )
}

export default Logo
