import React, { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import CheckIcon from '../../assets/media/check.svg'
import NewConfigurationIcon from '../../assets/media/new-configuration-small.svg'
import PdfIcon from '../../assets/media/pdf.svg'
import { ImagesPreview } from '../../base-components/images-preview'
import { useCurrentBrand } from '../../hooks/useCurrentBrand'
import useTranslation from '../../hooks/useTranslation'
import {
    resetConfigurationAsync,
    setConfigurationRescueConflictAccepted,
} from '../../redux/actions/app/configuration.actions'
import CartboxPrices from '../cartbox-prices/CartboxPrices'
import Modal, { ModalClosingButton } from '../modal'
import Tabbox from '../tabbox'
import { OptionsTab } from './componenets/OptionsTab'
import StandardsTab from './componenets/StandardsTab'
import TechnicalDataTab from './componenets/TechnicalDataTab'
import { useCurrentConfigurationFromVehicleCode } from './hooks/useCurrentConfigurationFromVehicleCode'
import { useMappedConfiguration } from './hooks/useMappedConfiguration'
import { useModalState } from './hooks/useModalState'
import { usePdfDownload } from './hooks/usePdfDownload'
import {
    Container,
    Footer,
    FooterButton,
    FooterButtonsContainer,
    InfoRow,
    InfoText,
    PriceSection,
    ShowDetailsButton,
    Subtitle,
    TabContainer,
    Title,
} from './rescueVehicleCodeModal.styles'
import {
    resetVehicleCodeStatus,
} from '../../redux/actions/app/vehicleCode.actions'
import { adoptConflictSolution } from '../../redux/actions/app/conflictSolution.actions'
import RescueVehicleCodePriceModal from './RescueVehicleCodePriceModal'

export type Props = {}

export const ExportPdfButton: FunctionComponent = () => {
    const { t } = useTranslation()
    const { data } = useCurrentConfigurationFromVehicleCode()
    const { download, loading } = usePdfDownload(data)

    return (
        <FooterButton icon={<PdfIcon />} onClick={download} loading={loading}>
            {t('pdf.buttonLabel')}
        </FooterButton>
    )
}

export const NewConfigurationButton: FunctionComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const onClick = useCallback(() => {
        dispatch(resetConfigurationAsync.request(undefined, undefined))
        dispatch(resetVehicleCodeStatus())
    }, [dispatch])

    return (
        <FooterButton icon={<NewConfigurationIcon />} onClick={onClick}>
            {t('configuration.reset')}
        </FooterButton>
    )
}

export const AcceptButton: FunctionComponent = () => {
    const { t } = useTranslation()
    const brand = useCurrentBrand()
    const dispatch = useDispatch()
    const onClick = useCallback(() => {
        dispatch(setConfigurationRescueConflictAccepted(true))
        dispatch(adoptConflictSolution())
    }, [dispatch])
    return (
        <FooterButton icon={<CheckIcon />} onClick={onClick}>
            {t(`vehicleCode.${brand}.rescue.buttonAccept`)}
        </FooterButton>
    )
}

const RescueVehicleCodeModal: FunctionComponent<Props> = () => {
    const brand = useCurrentBrand()
    const { isRescue, close, isRepairable, mode, priceChanges } = useModalState()
    const priceChanged = priceChanges != null
    const { audiCode, standardsHeadline, optionsHeadline, technicalDataHeadline, modelName, prices, images } =
        useMappedConfiguration()
    const { t } = useTranslation()
    const [showDetails, setShowDetails] = useState(false)

    return (
        priceChanged
            ? <RescueVehicleCodePriceModal/>
            : <Modal isVisible={isRescue}>
            <ModalClosingButton onClick={close} />
            <Container>
                <Title>{t(`vehicleCode.${brand}.rescue.title`)}: {audiCode}</Title>
                <Subtitle>{modelName}</Subtitle>
                <InfoRow>
                    <PriceSection>
                        <CartboxPrices prices={prices} isHidden={false} mode={mode}/>
                    </PriceSection>
                    <ImagesPreview images={images} />
                </InfoRow>
                {!showDetails && (
                    <ShowDetailsButton variant="secondary" onClick={() => setShowDetails(true)}>
                        {t(`vehicleCode.${brand}.rescue.buttonShowConfigurationDetails`)}
                    </ShowDetailsButton>
                )}
                {showDetails && (
                    <Tabbox
                        tabs={[
                            {
                                id: 'options',
                                label: t(`pdfTranslations.${optionsHeadline}`),
                                isActive: true,
                                content: (
                                    <TabContainer>
                                        <OptionsTab />
                                    </TabContainer>
                                ),
                            },
                            {
                                id: 'standards',
                                label: t(`pdfTranslations.${standardsHeadline}`),
                                isActive: false,
                                content: (
                                    <TabContainer>
                                        <StandardsTab />
                                    </TabContainer>
                                ),
                            },
                            {
                                id: 'technical-data',
                                label: t(`pdfTranslations.${technicalDataHeadline}`),
                                isActive: false,
                                content: (
                                    <TabContainer>
                                        <TechnicalDataTab />
                                    </TabContainer>
                                ),
                            },
                        ]}
                    />
                )}
                <InfoText>
                    {isRepairable
                        ? t(`vehicleCode.${brand}.rescue.textRepairable`)
                        : t(`vehicleCode.${brand}.rescue.textNotRepairable`)}
                </InfoText>
                <Footer>
                    <FooterButtonsContainer>
                        <ExportPdfButton />
                        <NewConfigurationButton />
                        {isRepairable ? <AcceptButton /> : null}
                    </FooterButtonsContainer>
                </Footer>
            </Container>
        </Modal>
    )
}

export default RescueVehicleCodeModal
