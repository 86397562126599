import { MapStateToProps, connect } from 'react-redux'
import logoIsHiddenSelector from '../../redux/selectors/feature-scope/logoIsHiddenSelector'
import { Props } from './Logo'
import brandSelector from '../../redux/selectors/brandSelector'
import { resetConfigurationAsync } from '../../redux/actions/app/configuration.actions'

type StateProps = Pick<Props, 'isHidden' | 'brand'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isHidden: logoIsHiddenSelector(state),
    brand: brandSelector(state),
})

type DispatchProps = Pick<Props, 'resetConfiguration'>

const mapDispatchToProps: DispatchProps = {
    resetConfiguration: resetConfigurationAsync.request,
}

export default connect(mapStateToProps, mapDispatchToProps)
