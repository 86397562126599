import { connect, MapStateToProps } from 'react-redux'
import { Props } from './VehicleCodeModal'
import vehicleCodeSelector from '../../redux/selectors/vehicleCodeSelector'
import vehicleCodeRequestIsPendingSelector from '../../redux/selectors/pending-request/vehicleCodeRequestIsPendingSelector'
import brandSelector from '../../redux/selectors/brandSelector'
import vehicleCodeModalShouldBeShownSelector
    from '../../redux/selectors/vehicleCodeModalShouldBeShownSelector'

type StateProps = Pick<Props, 'isLoading' | 'vehicleCode' | 'brand' | 'modalShouldBeShown'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isLoading: vehicleCodeRequestIsPendingSelector(state),
    vehicleCode: vehicleCodeSelector(state),
    brand: brandSelector(state),
    modalShouldBeShown: vehicleCodeModalShouldBeShownSelector(state),
})

export default connect(mapStateToProps)
