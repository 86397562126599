import { createReducer } from 'typesafe-actions'
import VehicleCode from '../../entities/VehicleCode'
import {
    setVehicleCodeModalShouldBeShownState,
    setVehicleCodeState,
} from '../actions/app/vehicleCode.actions'

export type VehicleCodeState = {
    code: VehicleCode
    modalShouldBeShown: boolean
}

export const defaultState: VehicleCodeState = {
    code: '',
    modalShouldBeShown: false,
}

const vehicleCodeReducer = createReducer(defaultState)
    .handleAction(setVehicleCodeState, (state, action) =>  ({
        ...state,
        code: action.payload,
    }))
    .handleAction(setVehicleCodeModalShouldBeShownState, (state, action) =>  ({
        ...state,
        modalShouldBeShown: action.payload,
    }))

export default vehicleCodeReducer
