import { createSelector } from 'reselect'
import cartSelector from './cartSelector'
import CartAdditionalInformation from '../../../entities/CartAdditionalInformation'

const carAdditionalInformationSelector = createSelector(
    cartSelector,
    (cart): CartAdditionalInformation => cart.additionalInformation,
)

export default carAdditionalInformationSelector
