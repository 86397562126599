import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CartboxAdditionalInformation'
import cartAdditionalInformationSelector
    from '../../redux/selectors/cart/cartAdditionalInformationSelector'

type StateProps = Pick<Props, 'additionalInformation'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    additionalInformation: cartAdditionalInformationSelector(state),
})

export default connect(mapStateToProps)
